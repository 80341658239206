.earth-view .controls {
  pointer-events: none;
  position: fixed;
  right: 20px;
  top: 20px;
}



.earth-view .controls p {
  clear: both;
  margin: 0px;
  margin-bottom: 5px;
  display: block;
  text-align: right;
  padding: 0px;
}

.earth-view .controls .label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.9;
  margin: 0px;
  padding: 0px;
  margin-bottom: 2px !important;
}


.earth-view .controls .button-container {
  display: flex;
  justify-content: flex-end;
}

.earth-view .controls button.ui.button {
  pointer-events: auto;
  display: block;
  width: 70px;
  margin-bottom: 5px;
  margin-right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}




.earth-view .mobile-control-bar {
  height: 90px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: var(--main-bg-color);
  pointer-events: auto;
  overflow-y: visible;
  display: flex;
  border-top: 1px solid var(--highlight);
}

.earth-view .mobile-control-bar .control-group-toggle-buttons-container {
  display: flex;
  height: 120px;
  overflow-x: scroll;
}

.earth-view .mobile-control-bar .control-group-toggle-buttons-container button.ui.button {
  margin-top: 10px;
  width: 120px;
  margin-left: 10px;
  margin-right: 10px;
}

.earth-view .mobile-control-bar .control-popup {
  position: absolute;
  left: 0px;
  right: 0px;
  padding:10px;
  bottom: 100%;
  border-top: 1px solid var(--highlight);
  z-index: 1000;
  background: var(--main-bg-color);
}

.earth-view .mobile-control-bar .mobile-control {

}

@media only screen and (max-width: 796px)  {
  .earth-view .controls {
    right: 10px;
    top: 10px;
  }
  .earth-view .controls button.ui.button {
    pointer-events: auto;
    display: block;
    width: 60px;
    margin-bottom: 5px;
    margin-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .earth-view .mobile-control-bar .control-group-toggle-buttons-container button.ui.button {
    margin-top: 10px;
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
