.render-controls {
  user-select: none;
  text-align: right;
}

.opacity-controls {
  padding-top: 20px;
}

.opacity-controls > div {
  display: flex;
  justify-content: flex-end;
}

.rc-slider {
  margin-bottom: 5px;
  margin-right:10px;
}



.rc-slider .rc-slider-track {
  background-color: var(--main-text-color) !important;
}

.rc-slider .rc-slider-handle {
  pointer-events: auto;
  border: solid 2px var(--main-text-color) !important;
}

.rc-slider .rc-slider-handle:active {
  border: solid 2px var(--main-text-color) !important;
  box-shadow: 0px 0px 5px var(--main-text-color) !important;
}

.rc-tooltip {
  z-index: 100;
}
.rc-slider-tooltip { z-index: 1500; }


.theme-selector {
  text-align: right;
}

.theme-selector button.ui.button {
  pointer-events: auto;
  display: inline-block!important;
  text-align: right;
  width: 120px !important;
  margin-top: 15px !important;
}

.theme-selector .theme button {
  border: 0px solid transparent;
  background: transparent;
  box-shadow: 0px 0px transparent;
  width: 100%;
  outline: 0;
}

.theme-selector .theme .theme-select-button {
  pointer-events: auto;
  display: flex !important;
  justify-content: flex-end;
  cursor: pointer;
  padding: 3px;
}

.theme-selector .theme .theme-color-box {
  width: 20px;
  height: 20px;
  margin: 0px;
}

.theme-selector .theme:hover .theme-color-box {
  opacity: 0.8;
}



.theme-selector .theme-options {
  overflow: hidden;
  height: 0px;
  background: rgba(240,240,240, 0);
  border-radius: 1px;
  border: 1px solid rgba(255,255,255,0);
  transition: height 0.5s, border 0.5s, background 0.5s;
}

.theme-selector .theme-options.showing {
  height: 105px;
  background: rgba(240,240,240, 0.5);
  border: 1px solid var(--fill);
}

@media only screen and (max-width: 796px)  {
  .line-width-options {
    display: flex;
    justify-content: flex-end;
  }

  .line-width-options button.ui.button {
    margin-left: 5px;
  }
}
