
#earth-view-overlay-container {
  position: absolute;
  z-index: 100;
  pointer-events: none;
}

.overlay {
  user-select: none;

}

.overlay .satellite-label-position {
  width: 200px;
  opacity: 0.5;
}
.overlay .satellite-label-position.closest {
  z-index: 10000;
  pointer-events: none;
  cursor: pointer;
  opacity: 1;
}

.overlay .satellite-label-position.closest.selected {
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
}

.overlay .satellite-label-position .label-container {
  display: inline-block;
  border-radius: 3px;
}

.overlay .satellite-label-position .label-container .row {
  display: flex;
}

.overlay .satellite-label {
  position: relative;
  display: inline-block;
  opacity: 0.5;
  padding: 3px;
  font-size: 10px;
  font-weight: 400;

  border-radius: 3px;
  transition: 0.1s border, 0.1s color, 0.1s background, 0.1s font-weight;
}

.overlay .satellite-label.hovered {
  opacity: 1;
}

.overlay .satellite-label.selected {
  pointer-events: auto;
  text-shadow: none;
  cursor: pointer;
}

.overlay .satellite-label.closest {
  color: white;
  font-weight: 700;
  z-index: 1100;
}

.overlay .satellite-label-position .circle {
  position: absolute;
  left: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  border: 2px solid rgba(40,40,40,1);
  border-radius: 10px;
  transition: 0.1s opacity;
  pointer-events: none;
}

.overlay .satellite-label-position.closest .circle {
  opacity: 1;
}

.overlay .behind-earth-label {
}

.overlay .satellite-label .label-controls {
  display: inline-block;
  width: 10px;
}


.label-container p {
  margin-bottom: 0px;
  color: var(--main-text-color);
}

.label-container.dragging-orbit {
  pointer-events: none !important;
}

.expand-container {
  transition: transform 0.2s;
  padding: 2px;
  transform-origin: 8.5px 9.5px;
  height:20px;
}


.label-container .expand-button {
  stroke: white;
  width: 15px;
  height: 15px;
  stroke-width: 2px;
  pointer-events: auto;
  cursor: pointer;
  border-radius: 3px;
}

.label-container .expand-button:hover {
  background: rgba(255,255,255,0.7);
}

.label-container .expand-information {
  transition: height 0.1s;
  overflow: hidden;
}

.sat-graphs {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}

.sat-graphs p {
  color: var(--main-text-light);
}

.sat-graphs p span {
  padding-left: 5px;
  float: right;
}

.sat-graphs p span strong {
  padding-right: 1px;
}

.sat-graphs button.ui.tiny.button {
  color: var(--main-text-light) !important;
  background: var(--fill-dark);
  margin-top:3px;
  padding-top:3px;
  padding-bottom: 3px;
  width: 100%;
}

.sat-graphs button.ui.tiny.button:hover {
  opacity: 0.7;
}
