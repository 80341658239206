.animation-controls {
  user-select: none;
  margin-top: 25px;
}

.animation-controls button.ui.button.play-pause-button {
  width: 80px !important;
}

.animation-controls .speed-controls {
  text-align: right;
}

.animation-controls .speed-controls > button.ui.tiny.button {
  display: inline-block !important;
  width: inherit;
  margin: 0px;
  padding: 2px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left:3px;
}

.animation-controls .speed-controls > button.ui.tiny.button svg {
  width: 18px;
  height: 18px;
  stroke-width: 3px;
}
