.search-results {
  margin-top: 20px;
  max-width: 300px;
  user-select: none;
}

.search-results h3 {
  margin: 0px;
  padding: 0px;
  padding-bottom: 5px;
}

.search-results h3 span {
  font-weight: 100;
}

.search-results .result-list-container {
  background: rgba(255,255,255,0.01);
  pointer-events: auto;
}

.search-result {
  position: relative;
  border-radius: 3px;
  background: rgba(255,255,255,0.5);
  margin-bottom: 0px;
  margin-right: 5px;
  max-width: 300px;
  padding: 2px;
  padding-left: 5px;
  user-select: none;
  pointer-events: auto;
  cursor: pointer;
}

.search-result > p {
  margin: 0px;
}

.search-result.selected {
  color: white;
}

.data-source-date {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
  text-align: right;
}

.data-source-date .hard-date {
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0;
}

.data-source-date .from-now {
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 1;
}

.search-result .extra-info {
  padding-bottom: 10px;
}

.search-result .extra-info p {
  margin: 0px;
  padding: 0px;
  font-size: 0.8em;
}

.data-source-date:mouseover .hard-date {
  opacity: 1;
}

.data-source-date:mouseover .from-now {
  opacity: 0;
}
