.filters-list {
  margin-right: 20px;
  margin-top: 100px;
  pointer-events: auto;
}

.filters-list h3 {
  margin-bottom: 0px;
  color: var(--main-text-color);
  cursor: pointer;
  user-select: none;
}

.filters-list h3.disabled {
  opacity: 0.7;
}

.filters-list h3:hover {
  margin-bottom: 0px;
  cursor: pointer;
  opacity: 0.8;
}

:root {
  --checkbox-size: 17px;
}


.filters-list .filter-toggle-checkbox {
  pointer-events: auto;
  margin-right: -5px;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

.filters-list .filter-toggle-checkbox .ui.fitted.checkbox {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

.filters-list .filter-toggle-checkbox .ui.fitted.checkbox input {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}


.filters-list .filter-toggle-checkbox .ui.fitted.checkbox label:before {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

.filters-list .filter-toggle-checkbox .ui.fitted.checkbox label:after {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  top: 0px;
  font-size:15px;
}

.filters-list .filter {
  position: relative;
}


.filters-list .ui.mini.input {
  width: 100px;
  position: absolute;
  overflow-y: hidden;
  top: 0;
  pointer-events: auto;
}

.filters-list .rule-bar {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  pointer-events: auto;
  user-select: none;
}

.filters-list .controls-container{
  margin-bottom: 30px;
  padding: 5px;
  background: var(--main-bg-color-opacity-90);
  border: 1px solid var(--main-bg-color);
  border-radius: 5px;
}

.filters-list .filter-suggestions {
  padding-top: 10px;
}

.filters-list button.ui.button.mini {
  margin-bottom: 3px;
}

.filters-list button.ui.button.tiny {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: .85714286rem;
}


  .filters-list input {
    background: blue;
  }
@media only screen and (max-width: 796px) {

  .filters-list .top-hide-button {
    position: fixed;
    z-index: 1000;
    box-shadow: 0px 0px 15px rgba(255,255,255,0.5) !important;
    top: 4px;
    width: 200px !important;
    left: -200px;
    transition: left 0.5s;
  }
  .filters-list .top-hide-button.showing {
    left: 20px;
  }

  .filters-list .ui.mini.input {
    max-width: 65px !important;
    overflow: hidden;
  }
  .filters-list input {
    max-width: 65px !important;
    padding: 4px !important;
  }

  .filters-list .rule-bar button.ui.button.mini {
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
