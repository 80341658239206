.App {
  user-select: none;
  background: var(--main-bg-color);
}

h1, h2, h3, h4, h5, p, li, ul, button {
  user-select: none;
  color: var(--main-text-color);
}

a {
  color: var(--fill-dark);
  user-select: none;
}

h3 {
  font-size: 16px;
}

.App header {
  padding: 0px;
}

.App header h1 {
  font-size: 24px;
  margin: 0px;
  user-select: none;
}

.App header .about-link {
  pointer-events: auto;
  display: inline-block;
  user-select: none;
  opacity: 0.8;
}

.App .earth-view {
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: hidden;
}

.App .earth-view .visualisation {
  position: fixed;
  cursor: grab;
  --variable-height-calculation: calc( 100vh - 100vw ) * 0.5 ;
  top: calc( min( 100vh - 100vw, 0vh ) * 0.5 );
}

.App .earth-view .visualisation:active {
  cursor: grabbing;
}

.App .earth-view .controls {
  z-index: 1000;
}

.App .scroll-overlay {
  z-index: 10;
  pointer-events: none;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.App .search-controls {
  position: relative;
  z-index: 10;
  pointer-events: none;
  overflow: hidden;
  padding: 20px;
  padding-bottom: 10px;
}

.App .search-controls .filter-controls {
  position: relative;
}

.App .scroll-overlay .earth-view-spacer {
  height: min( 75vh, 65vh );
  pointer-events: none;
  user-select: none;
}




button {
  pointer-events: auto;
}

.App button.ui.button {
  background-color: var(--fill);
  color: var(--main-text-color);
  font-weight: 400;
}

.App button.ui.basic.button {
  color: var(--main-text-color) !important;
  font-weight: 400;
}


.App button.ui.button:hover {
  background-color: var(--fill-hover);
}

.App button.ui.basic.button:hover {
  background: transparent !important;
  opacity: 0.7 !important;
  font-weight: 400;
}

.App button.ui.active.button:hover {
  background-color: var(--highlight-hover) !important;
}
.App button.ui.active.button {
  background-color: var(--highlight) !important;
  color: var(--main-text-inverse) !important;
}

.search-controls .filter-box {
  position: absolute;
  width: 400px;
  max-width: calc( 100vw - 20px);
  top: 45px;
  left: -400px;
  transition: left 0.5s;
}

.search-controls .filter-box.showing {
  left: 20px;
}

.search-controls .search {
  margin-left: 0px;
  max-width: 1000px;
  transition: margin-left 0.5s;
}

.search-controls .search.filter-showing {
  margin-left: 400px;
}


.App .hide-ui-button-container {
  position: fixed;
  right: 5px;
  bottom: 30px;
  z-index: 1000;
}

.App .hide-ui-button-container button.ui.button {
  background: var(--main-bg-color-opacity-25);
  padding: 5px;
  color: var(--fill);
}

@media only screen and (max-width: 796px)  {
  .App header h1 {
    font-size: 18px;
    margin: 0px;
    user-select: none;
  }

  .App header .about-link {
    pointer-events: auto;
    display: inline-block;
    user-select: none;
    opacity: 0.8;
  }

  .App .search-controls {
    padding:10px;
    padding-bottom: 0px;
  }

  .App .scroll-overlay .earth-view-spacer {
    height: min( 45vh );
  }

  .App .footer {
    padding-bottom: 50px;
  }

  .App .hide-ui-button-container {
    bottom: 100px;
  }
}
