.earth-view .top-message-bar {
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 20;
  top: 60px;
  width: 70vw;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 5px;
  background: #F2A30F;
}

.earth-view .not-rendering-orbits-message {
  z-index: 20;
  font-size: 11px;
  opacity: 0.95;
  margin: 0px;
  color: white;
  position: relative;
}

.earth-view .hide-message-button {
  background: white !important;
  color: #F2A30F !important;
}

.App .top-message-bar {

}

.App .top-message-bar button.ui.button {
  display: block;
  width: 100%;
  background: white;
  margin-top: 5px;
  padding: 5px;
}
