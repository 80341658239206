.timeline {
  text-align: right;
  position: fixed;
  right: 10px;
  bottom: 5px;
  font-size: 0.85em;
  opacity: 0.7;
  z-index: 20;
  user-select: none;
  pointer-events: none;
  z-index: 1001;
}
