.applied-filters-summary {
  position: absolute;
  top: -30px;
}

.applied-filters-summary button.ui.button {
  border: 0px solid transparent !important;
  margin-left: 0px;
  position: relative;
  top: -1px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px transparent !important;
}
