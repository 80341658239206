body {
  margin: 0;
  font-family: "Europa", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, h1, h2, h3, h4, h5, h6, p, li, ul, input, span {
  font-family: 'Europa';
}

h1 {
  font-family: 'Europa';
}

input {
  font-family: 'Europa' !important;
}

button {
  font-family: 'Europa' !important;
}


.ui.input > input {
  font-family: 'Europa';
}
