.search-suggestions {
  margin-top: 5px;
  width: 40vw;
  min-width: 300px;
}

.suggestions-text {
  user-select: none;
}

.suggestions-text p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 5px;
  font-size: 0.8em;

}

.suggestion-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}

.search-box .ui.input input {
  border: 1px solid var(--fill);
  color: var(--main-text-color);
  background: var(--main-bg-color);
}

.search-box .ui.input input:focus {
  border: 1px solid var(--fill-hover);
  color: var(--main-text-color);
  background: var(--main-bg-color);
}

.search-box .ui.input i.search.icon {
  color: var(--main-text-color) !important;
}

.suggestion-buttons button {
  margin-bottom: 4px !important;
  background: var(--fill-dark) !important;
  color: var(--main-text-inverse) !important;
  pointer-events: auto;
}

.suggestions-text .search-suggestion:hover {
  background: var(--fill-dark-hover) !important;
}
