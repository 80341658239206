.contractor-filter .contractor-search-results {
  position: absolute;
  width: 400px;
  right: -400px;
  top: -200px;
  height: 400px;
  background: rgba(255,255,255,0.9);
  padding: 10px;
  border-radius: 5px;
  overflow-y: scroll;
  pointer-events: auto;
  z-index: 10000;
}

.contractor-filter .contractor-search-results button {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.contractor-filter .selected-contractors button {
  margin-bottom: 5px;
}
