.orbit-distance-comparison {
  max-width: 760px;
  position: relative;
  height: 0px;
  margin-bottom: 0px;
  overflow-y: hidden;
  opacity: 0;
  transition: height 0.9s, opacity 0.9s, margin-bottom 0.9s, margin-top 0.5s;
}

.orbit-distance-comparison.showing {
  opacity: 1;
  height: 280px;
  margin-bottom: 10px;
  margin-top: -30px;
}

.orbit-distance-comparison-graph {
  position: relative;
  height: 200px;

  overflow: hidden;
  margin-bottom: 5px;
}

.orbit-distance-comparison .svg-container {
  max-width: 760px;
  max-height: 210px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.App:not(.dragging-orbit) .orbit-distance-comparison .svg-container {
  pointer-events: auto;
}

.App.dragging-orbit .orbit-distance-comparison .svg-container {
  pointer-events: none;
}

.orbit-distance-comparison .svg-container svg {
  user-select: none;
  cursor: crosshair;
  background: var(--main-bg-color-opacity-75);
  border: 1px solid rgba(220,220,220,0.8);
  border-radius: 2px;
}




.orbit-distance-comparison .controls {
  display: flex;
}

.orbit-distance-comparison .controls p {
  margin-top: 4px;
}

.orbit-distance-comparison .controls .button-row {
  margin-left: 10px;
}

.orbit-distance-comparison button {
  pointer-events: auto;
}

.comparison-render-controls {
  margin-bottom: 5px;
  display: flex;
}

.comparison-render-controls p {
  margin-right: 10px;
  margin-bottom: 5px;
  opacity: 0.7;
  position: relative;
  top: 1px;
}

.comparison-render-controls button.ui.button {
  padding: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
}

@media only screen and (max-width: 796px)  {
  .orbit-distance-comparison .controls .button-row  button.ui.button {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .orbit-distance-comparison.showing {
    height: 310px;
  }
}
