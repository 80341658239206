.orbit-height.filter .element-title {
  text-align: center;
  margin: 0px;
  padding: 0px;
  padding-bottom: 2px;
}

.orbit-height.filter .elements {
  margin-top: -0px;
}
