.App .footer {
  position: relative;
  pointer-events: auto;
  z-index: 10;
  background: var(--main-bg-color);
  border-top: 1px solid var(--fill);
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
}

.footer-col {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
}

.footer-col p {
  padding-top: 7px;
}

.footer .mail-subscribe-col {
  display: flex;
}

.mail-subscribe {
  position: relative;
  padding-left: 5px;
}
.mail-subscribe input {
  height: 35px;
}
.mail-subscribe button {
  height: 35px;
  position: relative;
  top: 1px;
  left: 5px;
}


@media only screen and (max-width: 796px)  {
  .footer-col {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .footer {
    margin-bottom: 90px;
  }
  .footer .mail-subscribe-col {
    display: block;
  }
}
